import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'


import pic_theme_argon from '../assets/images/vue-theme-agon-design.jpg'
import pic_theme_vuemail from '../assets/images/vue-theme-vuemail.jpg'
import pic_theme_materialkit from '../assets/images/vue-theme-material-kit.jpg'
import pic_theme_nowuikit from '../assets/images/vue-theme-now-ui-kit.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Vue Themes - Index provided by @AppSeed.us"
                    meta={[
                        { name: 'description', content: 'Vue Themes. Crafted by HTML5 Up, Creative-Tim. Enhanced by AppSeed' },
                        { name: 'keywords', content: 'vue-themes, vue, vue.js, themes, html5up, creative-tim' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">

                        <article style={{backgroundImage: `url(${pic_theme_argon})`}}>
                            <header className="major">
                                <h3>Argon Design</h3>
                                <p> Vue.js theme crafted by Creative-Tim</p>
                            </header>
                            <Link className="link primary" to="/vue-theme-argon-design"></Link>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_vuemail})`}}>
                            <header className="major">
                                <h3>VueMail</h3>
                                <p> Free vue Theme theme designed by ThemeSINE</p>
                            </header>
                            <a target="_blank" class="link primary" href="http://demo.themesine.com/vuemail-dark/#/"></a>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_nowuikit})`}}>
                            <header className="major">
                                <h3>Now UI Kit</h3>
                                <p>Vue Theme crafted by Creative-Tim</p>
                            </header>
                            <Link className="link primary" to="/vue-theme-now-ui-kit"></Link>
                        </article>                        

                        <article style={{backgroundImage: `url(${pic_theme_materialkit})`}}>
                            <header className="major">
                                <h3>Material Kit</h3>
                                <p>Vue Theme designed by Creative-Tim</p>
                            </header>
                            <Link className="link primary" to="/vue-theme-material-kit"></Link>
                        </article>                        

                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>About</h2>
                            </header>
                            <p>This index will be constanly updated with Free & Paid Vue Themes ready to be used in production. </p>
                            <ul className="actions">
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://appseed.us" className="button">Generate FullStack Apps in Vue.js</a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex