import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
            <li><a target="_blank" rel="noopener noreferrer" href="https://blog.appseed.us/vue-themes-dashboards-landing-pages/" className="icon alt fa-rss"><span className="label">Blog</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/vue-theme/index" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/webappseed" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/webappseed" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/webappseed" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; AppSeed.us</li><li>{' React, Vue.js '}<a href="https://appseed.us">FullStack App Generator</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
